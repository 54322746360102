import { createStore } from 'redux';
import { counter } from './reducers';

const partnerQuestions = [
  'you are hangry',
  'you are sleepy',
  'I said your arms are "weirdly long"',
  "I tried out the 'bean protocol'",
  'I said McDonalds was not "real food"',
  "I said Beyonce's music didn' match up to her performing",
  'you are being moody',
  'I said my potrait of you was "more accurate than the real thing"',
  'I rescheduled our date to Mcdonalds',
  'I said I liked Ben Affleck as Batman',
  'you are on your period',
];

const friendQuestions = [
  'you are hangry',
  'I was being loud at 2am again',
  'I stole your shampoo and didnt put it back',
  'I talked shiz to you during party golf',
  "I wouldn't stop meowing at the cat while you are in zoom meetings",
  'I said your new pants were "reminded me of the 1950s',
  'I forgot trash night again',
  'I still have that one shirt of yours that you didnt know i took',
  'I wore shoes in the house after you specifically asked me not to',
  'I said you looked like a ghost',
];

//redux

const defaultState = {
  count: 0,
  partnerQuestions,
  friendQuestions,
  questionCount: 0,
};

export const store = createStore(counter, defaultState);
