import React from 'react';
import './About.css';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Kristin from '../images/kristin.jpg';
import InstagramIcon from '../images/instagram-icon.png';
import GithubIcon from '../images/github-icon-1.png';
import LinkedinIcon from '../images/linkedin-icon.png';

const About = () => {
  return (
    <>
      <Container maxWidth="lg" align="center">
        <img src={Kristin} width="500" alt="Kristin" />
        <br />
        <img src={InstagramIcon} alt="instagram" className="icon" />
        <a
          href="https://github.com/aaronhargrove"
          target="_blank"
          rel="noreferrer"
        >
          <img src={GithubIcon} alt="Github" className="icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/aaron-hargrove-85948555"
          target="_blank"
          rel="noreferrer"
        >
          <img src={LinkedinIcon} alt="Linkedin" className="icon" />
        </a>
        <Typography variant="h4" className="creator-title">
          Aaron Hargrove
        </Typography>
      </Container>
      <Container maxWidth="sm" align="center">
        <Typography variant="body1">
          Aaron is a full stack developer and mortgage expert.
          <br />
          <br />
          When he's not helping people buy houses or making websites, he likes
          to play piano, climb things, play sports, read, and make exotic
          cuisines.
          <br />
          <br />
          He wants to thank his inspiration for making this quiz, everyone he
          has made mad. This include his siblings, roommates, ex-girlfriends,
          and multiple others. Thank you all!
          <br />
          Special thanks to his sister Kristin and friend Spencer who have
          encouraged and helped me learn things.
        </Typography>
      </Container>
    </>
  );
};

export default About;
