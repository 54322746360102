import React from 'react';
import NameForm from './NameForm';

import './Main.css';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

class Main extends React.Component {
  render() {
    return (
      <>
        <Container maxWidth="lg">
          <Typography variant="body1" align="center">
            <Typography variant="h3">ARE YOU...</Typography>
            <br />
            <Typography variant="h2">mad at me?</Typography>
            <br />
            Are you mad at me is a nice way to figure out if that person you
            think is mad at you is actually mad at you. It is great if people
            get mad at you a lot.
            <br />
            Fill out your name, their name, and send them the link to the quiz.
            <br />
            <br />
            If they are mad at you, we'll ask them to not be mad at you on your
            behalf. And maybe to go get some food together.
            <br />
            DISCLAIMER: This website makes 0 moeny so I can't afford to pay for
            your food, sorry
            <br />
            <br />
            {/* <Link to='/quiz'><Button variant="outlined" size='large'>Start</Button></Link>
                    <br /><br /> */}
            <NameForm />
          </Typography>
        </Container>
      </>
    );
  }
}

export default Main;
