import React from 'react';
import './Header.css';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { Link } from 'react-router-dom';

const Header = () => (
  <>
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" to="/">
        home
      </Link>
      <Link color="inherit" to="/about">
        about
      </Link>
      <Link color="textPrimary" to="/quiz">
        quiz
      </Link>
      <a href="https://can-u-guess-my-no.netlify.app/"> guess my number</a>
      <a href="https://100-dice-game.netlify.app/"> dice game</a>
    </Breadcrumbs>
    <br />
    <br />
  </>
);

export default Header;
